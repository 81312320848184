import React, { useState, useRef } from "react";
import { StyledNav, StyledNavbar } from "./styledComponents";
import websiteLogo from "./../../assets/images/wolf logo.svg";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { IconButton } from "@mui/material";
import MobileNav from "../mobileNav";

export default function Navbar() {
	const footerRef = useRef(null);
	const [open, setOpen] = useState(false);

	const returnHome = () => {
		return (window.location.href = "/");
	};

	const scrollToFooter = () => {
		footerRef.current = document.querySelector("#main-footer");

		if (footerRef.current) {
			footerRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	const switchMobileNav = () => {
		switch (open) {
			case true:
				setOpen(false);
				break;
			case false:
				setOpen(true);
				break;
			default:
				break;
		}
	};

	return (
		<>
			<MobileNav setOpen={setOpen} open={open} />
			<StyledNavbar id="#" className="">
				<StyledNav className="backdrop-blur-md nav" id="navbar">
					<div className="logo" onClick={returnHome}>
						<img src={websiteLogo} alt="Website Logo" />
					</div>
					<div className="links">
						<a
							href="/#"
							className="link"
							onClick={() => {
								window.scrollTo(0, 0);
							}}
						>
							Home
						</a>
						<a href="/#about" className="link">
							About
						</a>
						<a href="/#experience" className="link">
							Experience
						</a>
						<a href="/#skills" className="link">
							Skills
						</a>
						<a href="/gallery" className="link">
							Gallery
						</a>
					</div>
					<div className="nav-right">
						<button className="cta-button" onClick={scrollToFooter}>
							Contact
						</button>
					</div>
					<IconButton onClick={switchMobileNav} className="hamburger">
						{open ? (
							<CloseRoundedIcon
								className={`icon close-icon `}
								fontSize="large"
							/>
						) : (
							<MenuRoundedIcon className={`icon menu-icon`} fontSize="large" />
						)}
					</IconButton>
				</StyledNav>
			</StyledNavbar>
		</>
	);
}
