import styled from "styled-components";

export const StyledFooterContainer = styled.div`
	* {
		scroll-behavior: smooth !important;
	}

	scroll-margin-top: 3rem;

	.footer-credits {
		color: ${(props) => props.theme.footerTextColor};
		padding: 0 0 1rem 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 1rem;
		font-weight: 500;
		text-align: center;
		word-spacing: 0.5rem;
		opacity: 0.5;

		@media screen and (max-width: 768px) {
			margin: 3rem auto 0;
			padding: 0 0 2rem 0;
			scroll-margin-top: 0rem;
		}

		@media screen and (max-width: 479px) {
			width: 75%;
			margin: 3rem auto 0;
			scroll-margin-top: 0rem;
		}
	}
`;

export const StyledFooter = styled.div`
	color: ${(props) => props.theme.footerTextColor};
	padding: 1rem 12rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	column-gap: 2rem;

	@media screen and (max-width: 768px) {
		padding: 1rem 5rem;
		flex-direction: column;
	}

	@media screen and (max-width: 479px) {
		padding: 1rem 2rem;
		flex-direction: column;
	}

	.footer-logo-container,
	.footer-tag-line,
	.footer-social {
		opacity: 0;
	}

	.footer-logo-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: auto;
		width: 75%;

		@media screen and (max-width: 768px) {
			order: 2;
			margin: 2rem auto 0rem;
			width: 100%;
		}
	}

	.footer-logo {
		width: 12rem;
		height: 100%;
	}

	.footer-tag-line-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.footer-tag-line {
		margin-top: 1.5rem;
		font-size: 1.5rem;
		font-weight: 600;
		text-align: center;
		word-spacing: 0.5rem;
	}

	.footer-socials-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: 1.5rem;
		column-gap: 2.5rem;

		.footer-social {
			width: 2rem;
			height: 2rem;
			transition: all 0.3s ease-in-out;
			border-radius: 20%;

			&:hover {
				cursor: pointer;
				transform: scale(1.35) !important;
				animation: shadow-blue 1s ease-in-out infinite;
			}
		}
	}

	.footer-contact-area {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;

		@media screen and (max-width: 768px) {
			order: 1;
		}
	}

	.footer-contact-header {
		font-size: 1.75rem;
		font-weight: 600;
		margin-bottom: 1rem;
	}

	.contact-form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

	.row-1 {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		column-gap: 1rem;
		margin-bottom: 1rem;

		@media screen and (max-width: 479px) {
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}

	.contact-form-input {
		width: 100%;
		padding: 1rem;
		margin-bottom: 1rem;
		border-radius: 0.5rem;
		margin-top: 1rem;
		text-align: left;
		border: none;
		outline: none;
		font-size: 1rem;
		font-weight: 500;
		background-color: ${(props) => props.theme.contactFormInputBackground};
		color: ${(props) => props.theme.contactFormInputTextColor};
		box-shadow: ${(props) => props.theme.contactFormInputBoxShadow};

		&:focus {
			box-shadow: ${(props) => props.theme.contactFormInputBoxShadowFocused};
			outline: ${(props) => props.theme.contactFormInputOutlineFocused};
		}
	}

	.input-label {
		font-size: 1rem;
		font-weight: 400;
		letter-spacing: 0.04rem;

		text-align: left;
		margin-right: auto;
	}

	.contact-form-button {
		width: 100%;
		height: 3rem;
		border-radius: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			cursor: pointer;
			transform: scale(1.05) !important;
		}
	}
`;

export const StyledDivider = styled.div`
	width: 100%;
	height: 0.25rem;
	background-color: #00aaff;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border-radius: 0.5rem;
	opacity: 0.5;
	scale: 0.5;
`;
