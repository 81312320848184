import React from "react";
import { StyledToolboxContainer, StyledToolbox } from "./styledComponents";
import { devToolboxConfig, designToolboxConfig } from "./toolboxConfig";
import waveBG from "./../../assets/images/wave-bg.svg";
import { useSpring, animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";

/**
 * @method Toolbox
 */
export default function Toolbox() {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.2,
	});

	const devToolboxSpring = useSpring({
		opacity: inView ? 1 : 0,
		transform: inView ? "translateX(0)" : "translateX(-100px)",
		from: { opacity: 0, transform: "translateX(-100px)" },
	});

	const designToolboxSpring = useSpring({
		opacity: inView ? 1 : 0,
		transform: inView ? "translateX(0)" : "translateX(100px)",
		from: { opacity: 0, transform: "translateX(100px)" },
	});

	const devHeadingSpring = useSpring({
		opacity: inView ? 1 : 0,
		transform: inView ? "translateY(0)" : "translateY(50px)",
		from: { opacity: 0, transform: "translateY(50px)" },
	});

	const designHeadingSpring = useSpring({
		opacity: inView ? 1 : 0,
		transform: inView ? "translateY(0)" : "translateY(-50px)",
		from: { opacity: 0, transform: "translateY(-50px)" },
	});

	return (
		<StyledToolbox>
			<StyledToolboxContainer
				style={{ backgroundImage: `url(${waveBG})` }}
				id="skills"
				className="mb-14 mt-14 pt-10"
			>
				<div className="dev-toolbox" ref={ref}>
					<animated.h2
						style={devHeadingSpring}
						className="toolbox-heading dev-heading w-fit text-3xl primary font-semibold mb-9"
					>
						Dev Toolbox
					</animated.h2>
					<animated.div
						style={devToolboxSpring}
						className="dev-skill-container"
					>
						{devToolboxConfig?.map((skill, index) => (
							<div key={`${skill}-${index}`} className="dev-skill mb-4">
								<img
									className="mr-5 w-10 h-10 skill-image"
									src={skill.image}
									alt={skill.alt}
								/>
								<h4 className=" w-fit  text-xl text-white font-normal tracking-wide">
									{skill.name}
								</h4>
							</div>
						))}
					</animated.div>
					<div className="spacer" />
				</div>
				<div className="design-toolbox">
					<div className="spacer" />
					<animated.div
						style={designToolboxSpring}
						className="design-skill-container"
						ref={ref}
					>
						{designToolboxConfig?.map((skill, index) => (
							<div
								key={`${skill}-${index}`}
								className="design-skill mb-4 w-fit"
							>
								<h4 className="text-xl text-white font-normal tracking-wide">
									{skill.name}
								</h4>
								<img
									className="ml-5 w-10 h-10 skill-image"
									src={skill.image}
									alt={skill.alt}
								/>
							</div>
						))}
					</animated.div>
					<animated.h2
						style={designHeadingSpring}
						className="toolbox-heading design-heading w-fit text-3xl primary font-semibold mt-9 ml-auto"
					>
						Design Toolbox
					</animated.h2>
				</div>
			</StyledToolboxContainer>
		</StyledToolbox>
	);
}
