import styled from "styled-components";

export const StyledBackToTop = styled.div`
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;

	.back-to-top-button {
		padding: 0.5rem;
		border-radius: 50%;
		background-color: #333;
		color: white;
		border: none;
		cursor: pointer;
		transition: background-color 0.3s;

		&:hover {
			background-color: #555;
		}
	}
`;
