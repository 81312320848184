export const mainTheme = {
	dark: {
		backgroundColor: "#00011f",

		// nav
		navbarBlurredBackground: "#00305a10",
		navBackground: "#003f7530",
		navLinkColor: "#cacaca",
		contactButtonBackground:
			"radial-gradient(at 75% 75%, rgb(0, 140, 255, 0.5) 0,rgba(0, 19, 34, 1) 100%)",
		contactButtonBoxShadow: "0 0 1.25rem 0.25rem rgba(0, 0, 0, 0.1)",
		contactButtonTextColor: "#ffffff",

		// body
		bodyBackground:
			"radial-gradient(at 75% 75%, rgba(0, 71, 129, 0.5) 0,rgba(0, 19, 34, 1) 100%)",

		// bodyBackground:
		// 	"radial-gradient(at 11% 35%,hsla(212, 91%, 34%, 0.24) 0,hsla(338, 66%, 29%, 0.137) 50%),radial-gradient(at 84% 27%, rgba(0, 50, 97, 0.288) 0, #0e610b 100%), radial-gradient(   at 82% 75%,rgba(10, 28, 87, 0.219) 0, #330808 150%)",

		// hero
		heroTextColor: "#a5a5a5",
		heroEmphasisColor: "#0067c4",

		// heading
		headingTextColor: "#0067c4",

		// story
		storyTextColor: "#a5a5a5",

		// experience
		cardIconColor: "#d6d6d6",
		cardIconBackground: "#0076b1",
		cardTextColor: "#ffffff",
		cardDateColor: "#777777",
		cardDescriptionColor: "#d6d6d6",
		cardTitleColor: "#b4b4b4",
		experienceCardBackground: "#001c36",

		// toolbox
		toolboxTextColor: "#a5a5a5",
		skillImageBoxShadow: "0 0 25px rgba(0, 0, 0, 0.2)",

		// divider
		dividerColor: "#00aaff",

		// footer
		footerTextColor: "#d6d6d6",
		contactFormInputBackground: "#ffffff25",
		contactFormInputTextColor: "#ebebeb",
		contactFormInputBoxShadow: "0 0 0.5rem rgba(0, 0, 0, 0.2)",
		contactFormInputBoxShadowFocused: "0 0 0.5rem rgba(0, 0, 0, 0.5)",
		contactFormInputOutlineFocused: "1.5px solid #00aaff",

		// button
		buttonBackground:
			"radial-gradient(at 75% 75%,rgb(0, 140, 255, 0.5) 0,rgba(0, 19, 34, 1) 100%)",
		buttonBoxShadow: "0 0 1.25rem 0.25rem rgba(0, 0, 0, 0.1)",
		buttonTextColor: "#ffffff",

		// gallery
		galleryHeading: "#ffffff",

		// theme switcher
		themeSwitcherBackground: "#001c36",
	},
	light: {
		backgroundColor: "#95dcff",

		// nav
		navbarBlurredBackground: "#0067c410",
		navBackground: "#0069c430",
		navLinkColor: "#001c36",
		contactButtonBackground:
			"radial-gradient(at 75% 75%, rgba(0, 140, 255, 0.5) 0,#58b5fdff 100%)",
		contactButtonBoxShadow: "0 0 1.25rem 0.25rem rgba(0, 0, 0, 0.1)",
		contactButtonTextColor: "#001c36",

		// body
		bodyBackground:
			"radial-gradient(at 11% 35%,hsla(212, 100%, 53%, 0.24) 0,hsla(338, 92%, 59%, 0.137) 50%),radial-gradient(at 84% 27%, rgba(0, 132, 255, 0.288) 0, hsla(118, 91%, 59%, 0.062) 50% ), radial-gradient(   at 82% 75%,rgba(28, 81, 255, 0.219) 0, hsla(0, 87%, 64%, 0.068) 50%)",

		// hero
		heroTextColor: "#001c36",
		heroEmphasisColor: "#0067c4",

		// heading
		headingTextColor: "#002e58",

		// story
		storyTextColor: "#001c36",

		// experience
		cardIconColor: "#001c36",
		cardIconBackground: "#00a8ff",
		cardTextColor: "#001c36",
		cardDateColor: "#777777",
		cardDescriptionColor: "#4b4b4b",
		cardTitleColor: "#202020",
		experienceCardBackground: "#e2f1ff",

		// toolbox
		toolboxTextColor: "#001c36",
		skillImageBoxShadow: "0 0 25px rgba(0, 0, 0, 0.2)",

		// divider
		dividerColor: "#001c36",

		// footer
		footerTextColor: "#001c36",
		contactFormInputBackground: "#ffffff25",
		contactFormInputTextColor: "#ebebeb",
		contactFormInputBoxShadow: "0 0 0.5rem rgba(0, 0, 0, 0.2)",
		contactFormInputBoxShadowFocused: "0 0 0.5rem rgba(0, 0, 0, 0.5)",
		contactFormInputOutlineFocused: "1.5px solid #00aaff",

		// button
		buttonBackground:
			"radial-gradient(at 75% 75%,rgb(0, 140, 255, 0.5) 0,rgba(0, 19, 34, 1) 100%)",
		buttonBoxShadow: "0 0 1.25rem 0.25rem rgba(0, 0, 0, 0.1)",
		buttonTextColor: "#001c36",

		// gallery
		galleryHeading: "#001c36",

		// theme switcher
		themeSwitcherBackground: "#58b5fdff",
	},
};
