import styled from "styled-components";

export const StyledMobileNav = styled.nav`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	background: #013767f0;
	height: 45vh;
	margin-top: ${({ open }) => (open ? "7rem" : "-125%")};
	width: 60%;
	text-align: left;
	padding: 2rem 1.25rem 1.5rem;
	border-radius: 2.625rem;
	position: fixed;
	top: 0;
	right: 4%;
	overflow: hidden;
	z-index: 1000;

	transition: margin-top 0.5s ease-in-out;

	.logo {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 1.5rem;
		width: fit-content;
		height: auto;

		img {
			width: 25%;
			height: auto;
		}
	}

	.links {
		display: flex;
		flex-direction: column;
		row-gap: 0.75rem;
		width: fit-content;
	}

	.link {
		color: #fff;
		font-size: 1.05rem;
		font-weight: 400;
		text-decoration: none;
		padding: 0.5rem 1rem;
		transition: all 0.3s ease-in-out;
		border-radius: 20px;

		text-align: right;

		&:hover {
			transform: scale(1.15) !important;
			animation: shadow-white 1s ease-in-out infinite;
		}
	}

	.contact {
		background: radial-gradient(
			at 75% 75%,
			rgb(0, 140, 255, 0.5) 0,
			rgba(0, 19, 34, 1) 100%
		);
		animation: gradient 15s ease-in-out infinite;
		background-size: 600% 600%;
		transition: all 0.3s ease-in-out;
		border-radius: 20px;
		color: #fff;
		font-size: 1.05rem;
		font-weight: 500;
		margin-top: auto;
		text-decoration: none;
		padding: 0.75rem 1.5rem;
		box-shadow: 0 0 1.25rem 0.25rem rgba(0, 0, 0, 0.1);

		&:hover {
			transform: scale(1.1) !important;
			animation: shadow-blue 1s ease-in-out infinite,
				gradient 7s ease-in-out infinite;
		}
	}

	.contact-button {
		width: fit-content;
		background: radial-gradient(
			at 75% 75%,
			rgb(0, 140, 255, 0.5) 0,
			rgba(0, 19, 34, 1) 100%
		);
		animation: gradient 15s ease-in-out infinite;
		background-size: 600% 600%;
		transition: all 0.3s ease-in-out;
		border-radius: 10rem;
		color: #fff;
		font-size: 1.05rem;
		font-weight: 500;
		margin-top: auto;
		text-decoration: none;
		padding: 0.75rem 1.75rem;
		box-shadow: 0 0 1.25rem 0.25rem rgba(0, 0, 0, 0.1);
		border: none;
		margin-left: 0.25rem;

		&:hover {
			transform: scale(1.1) !important;
			animation: shadow-blue 1s ease-in-out infinite,
				gradient 7s ease-in-out infinite;
		}
	}
`;
