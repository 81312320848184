import styled from "styled-components";

export const StyledAbout = styled.div`
	.about-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0 15rem;
		color: #fff;
	}

	.about-title {
		font-size: 2rem;
		margin-bottom: 1rem;
		opacity: 1;
	}

	.about-text {
		font-size: 1.3rem;
		text-align: center;
	}
`;
