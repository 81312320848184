import styled from "styled-components";

export const StyledHeroContainer = styled.div`
	display: flex;
	flex-direction: column;

	.arrow-container {
		margin-top: -4rem;
		margin-bottom: 3rem;
		display: flex;
		align-items: center;
		justify-content: center;

		@media screen and (max-width: 768px) {
			margin-top: 2rem;
			margin-bottom: 4rem;
		}

		img {
			width: 1.5rem;
			height: 1.5rem;
			animation: hover 0.75s ease-in-out infinite alternate;

			&:hover {
				cursor: pointer;
			}
		}
	}

	@keyframes hover {
		0% {
			transform: translateY(0);
		}
		100% {
			transform: translateY(-0.5rem);
		}
	}
`;

export const StyledHero = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	margin-top: 8rem;
	padding: 0 2rem 5rem 2rem;
	box-sizing: border-box;
	overflow: hidden !important;
	height: 85vh;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		row-gap: 3rem;

		padding: 0 2rem 0rem 2rem;

		height: 100%;
		margin-top: 5rem;

		.hero-img {
			margin: auto !important;
			animation: none !important;
		}
	}

	@media screen and (max-width: 425px) {
		margin-top: 5rem;
		row-gap: 1rem;
	}

	.blob-1 {
		bottom: 5%;
		left: 5%;
		width: 25%;

		@media screen and (max-width: 768px) {
			bottom: 5%;
			left: 5%;
			width: 25%;
		}
	}

	.blob-2 {
		top: 10%;
		right: 12%;
		width: 30%;

		@media screen and (max-width: 768px) {
			top: 5%;
			right: 5%;
			width: 25%;
		}
	}

	.blob-3 {
		top: -25%;
		left: 25%;
		width: 25%;

		@media screen and (max-width: 768px) {
			top: 15%;
			left: 2%;
			width: 40%;
		}
	}

	.blob-4 {
		bottom: 5%;
		right: 40%;
		width: 15%;

		@media screen and (max-width: 768px) {
			bottom: 25%;
			right: 5%;
			width: 40%;
		}
	}

	.blob-1,
	.blob-2,
	.blob-3,
	.blob-4 {
		position: absolute;
		z-index: -1;
		animation: blob 15s ease-in-out infinite alternate-reverse;
	}

	.hero-image-container {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		scale: 0;

		.hero-img {
			margin-left: 6rem;
			width: 50%;
			height: 100%;

			@media screen and (max-width: 768px) {
				width: 50%;
			}

			@media screen and (max-width: 479px) {
				width: 75%;
			}
		}

		@media screen and (max-width: 768px) {
			margin-top: 2rem;
		}
	}

	.heading-container {
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0 10rem;
		width: 75%;
		text-align: right;
		color: ${(props) => props.theme.heroTextColor};
		margin-bottom: 1rem;

		@media screen and (max-width: 768px) {
			padding: 0;
			align-items: center;
			text-align: center;
			margin-top: 2rem;
		}

		.heading {
			font-size: 2.5rem;
			font-weight: 500;

			@media screen and (max-width: 768px) {
				font-size: 1.75rem;
			}
		}

		.sub-heading {
			font-size: 1.5rem;
			font-weight: 400;

			@media screen and (max-width: 768px) {
				font-size: 1.25rem;
			}
		}

		.heading,
		.emphasis,
		.sub-heading {
			text-shadow: 0 0 5rem rgba(0, 0, 0, 0.1);
		}

		.emphasis {
			opacity: 0;
			scale: 0;
		}

		.emphasis {
			font-weight: 600;
			color: ${(props) => props.theme.heroEmphasisColor};
		}
	}
`;
