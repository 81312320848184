/**
 * @method setLogos
 * @param {*} logos
 * @returns set logos
 */
export const setLogoArray = (logos) => {
	return {
		type: "SET_LOGOS",
		payload: logos,
	};
};

/**
 * @method setLoading
 * @param {*} loading
 * @returns set loading
 */
export const setLoading = (loading) => {
	return {
		type: "LOADING_LOGOS",
		payload: loading,
	};
};
