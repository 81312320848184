import React from "react";
import { StyledBackToTop } from "./styled";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import Button from "../button";

export default function BackToTop() {
	return (
		<StyledBackToTop>
			<Button
				className={"back-to-top-button"}
				onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
			>
				<ArrowUpwardRoundedIcon />
			</Button>
		</StyledBackToTop>
	);
}
