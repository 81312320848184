import React, { useEffect, useRef } from "react";
import { StyledMobileNav } from "./styledComponents";
// import websiteLogo from "./../../assets/images/wolf-logo-white.svg";

export default function MobileNav({ setOpen, open }) {
	const footerRef = useRef(null);
	const mobileNavRef = useRef(null);

	const scrollToFooter = () => {
		footerRef.current = document.querySelector("#main-footer");

		if (footerRef.current) {
			footerRef.current.scrollIntoView({ behavior: "smooth" });
		}
		setOpen(false);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				mobileNavRef.current &&
				!mobileNavRef.current.contains(event.target)
			) {
				setOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		document.addEventListener("touchstart", handleClickOutside);
		document.addEventListener("scroll", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			document.removeEventListener("touchstart", handleClickOutside);
			document.removeEventListener("scroll", handleClickOutside);
		};
	}, [mobileNavRef]);

	const navLinks = [
		{ text: "Home", href: "/#" },
		{ text: "About", href: "/#about" },
		{
			text: "Experience",
			href: "/#experience",
		},
		{ text: "Skills", href: "/#skills" },
		{ text: "Gallery", href: "/gallery" },
	];

	return (
		<StyledMobileNav ref={mobileNavRef} className="mobile-nav" open={open}>
			{/* <div className="logo">
				<img src={websiteLogo} alt="Website Logo" />
			</div> */}
			<div className="links">
				{navLinks?.map((link, index) => (
					<a
						href={link.href}
						key={`${link.name}-${index}`}
						className="link"
						onClick={() => setOpen(false)}
					>
						{link.text}
					</a>
				))}
			</div>
			<button className="contact-button" onClick={() => scrollToFooter()}>
				Contact
			</button>
		</StyledMobileNav>
	);
}
