/**
 * @method setCmsClient
 * @param {*} client
 * @returns cms client
 */
export const setCmsClient = (client) => {
	return {
		type: "SET_CLIENT",
		payload: client,
	};
};
