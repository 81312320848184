import styled from "styled-components";

export const StyledToolbox = styled.div``;

export const StyledToolboxContainer = styled.div`
	background-size: cover;
	background-repeat: repeat-x;
	background-position: center;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: ${(props) => props.theme.toolboxTextColor};
	column-gap: 16rem;
	scroll-margin-top: 6.5rem;
	width: 100%;
	animation: move-image 10s ease-in-out infinite alternate;

	@keyframes move-image {
		0% {
			background-position: 0 0;
		}
		100% {
			background-position: 100% 0;
		}
	}

	@media screen and (min-width: 1440px) {
		column-gap: 37rem;
	}

	@media screen and (min-width: 1069px) {
		column-gap: 28rem;
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;
		margin: 2rem auto;
	}

	.skill-image {
		box-shadow: ${(props) => props.theme.skillImageBoxShadow};
	}

	.spacer {
		height: 60px;

		@media screen and (max-width: 768px) {
			height: 0px;
		}
	}

	.dev-skill-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

	.design-skill-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
	}

	.design-skill {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		transition: all 0.3s ease-in-out;

		&:hover {
			transform: scale(1.1) !important;
		}
	}

	.dev-skill {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		transition: all 0.3s ease-in-out;

		&:hover {
			transform: scale(1.1) !important;
		}
	}

	.design-toolbox {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@media screen and (max-width: 768px) {
			.design-skill-container {
				order: 2;
			}
			.design-heading {
				order: 1;
			}
		}
	}

	.toolbox-heading {
		@media screen and (max-width: 768px) {
			text-align: center;
			margin: 2rem auto;
		}
	}

	.dev-toolbox {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.dev-toolbox,
	.design-toolbox {
		@media screen and (max-width: 768px) {
			width: 100%;
			padding: 0 5rem;
		}

		@media screen and (max-width: 479px) {
			width: 100%;
			padding: 0 2.5rem;
		}
	}

	@keyframes sideToSide {
		0% {
			width: 0%;
			left: 0;
		}
		33% {
			width: 100%;
			left: 0;
		}
		66% {
			width: 100%;
			right: 0;
		}

		100% {
			width: 0%;
			right: 0;
		}
	}
`;

export const StyledServicesContainer = styled.section`
	padding: 0 15rem;
	text-align: center;

	.services-heading {
		color: #00a8ff;
	}
`;
