import React from "react";
import {
	StyledExperienceCardContainer,
	StyledExperienceContainer,
	StyledStoryContainer,
} from "./styledComponents";
import { experience } from "./experienceConfig";
import Heading from "../heading";

import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "@react-spring/web";
import Divider from "../divider";
import ExperienceBubble from "../experienceBubble";

export default function ExperienceV1() {
	const [ref, inView] = useInView({
		threshold: 0.2,
		triggerOnce: true,
	});

	const partOneSpring = useSpring({
		opacity: inView ? 1 : 0,
		transform: inView ? "translateX(0)" : "translateX(-100px)",
		from: { opacity: 0, transform: "translateX(-100px)" },
	});

	const partTwoSpring = useSpring({
		opacity: inView ? 1 : 0,
		transform: inView ? "translateX(0)" : "translateX(100px)",
		from: { opacity: 0, transform: "translateX(100px)" },
	});

	return (
		<StyledExperienceContainer
			id="about"
			className="mt-20 display-flex justify-center"
			ref={ref}
		>
			<Heading className="story-heading mb-10 opacity-0 scale-0 w-fit m-auto">
				About me
			</Heading>
			<StyledStoryContainer className="mb-28">
				<animated.p style={partOneSpring} className="story">
					I'm a web developer with 3 years experience in frontend development,
					creating dynamic and responsive websites. Well-versed in Reactjs,
					JavaScript, HTML and CSS, and I'm currently taking an in-depth course
					on Nextjs to gain more knowledge about the framework. I utilize my
					experience in desktop support to create user-centric interfaces that
					are both visually appealing and functional. I'm a team player with a
					passion for tinkering and learning new things, and I'm always on the
					hunt for opportunities to improve my skillset.
				</animated.p>
				<animated.p ref={ref} style={partTwoSpring} className="story">
					Combining my web development skills with my creative side, I use Figma
					for creating wireframes and mockups for websites. Adobe Photoshop and
					Illustrator are also part of my playground for creating logos and
					other graphic design elements. I'm always looking for new projects to
					work on, so feel free to reach out if you have any questions or would
					like to collaborate.
				</animated.p>
			</StyledStoryContainer>

			<Heading
				id="experience"
				className="experience-heading mb-10 opacity-0 scale-0 w-fit m-auto"
			>
				My Experience
			</Heading>
			<StyledExperienceCardContainer className="mb-14">
				{experience.map((item, index) => {
					return (
						<ExperienceBubble item={item} key={`${item.title}-${index}`} />
					);
				})}
			</StyledExperienceCardContainer>

			<Divider />
		</StyledExperienceContainer>
	);
}
