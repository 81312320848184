import css from "./../../assets/images/css.svg";
import react from "./../../assets/images/react.svg";
import javascript from "./../../assets/images/js.svg";
import html from "./../../assets/images/html.svg";
import tailwind from "./../../assets/images/tailwind.svg";
import styledComponents from "./../../assets/images/styled-components.svg";
import next from "./../../assets/images/next.svg";

import ai from "./../../assets/images/ai.svg";
import ps from "./../../assets/images/ps.svg";
import xd from "./../../assets/images/xd.svg";
import figma from "./../../assets/images/figma.svg";
import sketch from "./../../assets/images/sketch.svg";
import wacom from "./../../assets/images/wacom.svg";

export const devToolboxConfig = [
	{
		name: "ReactJs",
		image: react,
		alt: "React Logo",
	},
	{
		name: "Nextjs",
		image: next,
		alt: "Nextjs Logo",
	},
	{
		name: "JavaScript",
		image: javascript,
		alt: "JavaScript Logo",
	},
	{
		name: "HTML",
		image: html,
		alt: "HTML Logo",
	},
	{
		name: "CSS",
		image: css,
		alt: "CSS Logo",
	},

	{
		name: "Tailwind CSS",
		image: tailwind,
		alt: "Tailwind CSS Logo",
	},
	{
		name: "Styled Components",
		image: styledComponents,
		alt: "Styled Components Logo",
	},
];

export const designToolboxConfig = [
	{
		name: "Adobe Illustrator",
		image: ai,
		alt: "Adobe Illustrator Logo",
	},
	{
		name: "Adobe Photoshop",
		image: ps,
		alt: "Adobe Photoshop Logo",
	},
	{
		name: "Adobe XD",
		image: xd,
		alt: "Adobe XD Logo",
	},
	{
		name: "Figma",
		image: figma,
		alt: "Figma Logo",
	},
	{
		name: "A Handy Sketchpad",
		image: sketch,
		alt: "A pencil drawing",
	},
	{
		name: "Wacom Intuos S",
		image: wacom,
		alt: "Wacom Logo",
	},
];
