import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/css";

import React, { useEffect, lazy, useState } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { createTheme, ThemeProvider } from "@mui/material";
import GlobalStyles from "./globalStyles";
import { mainTheme } from "./configs/theme";
const contentful = require("contentful");
import { useDispatch, useSelector } from "react-redux";
import { setCmsClient, setLoading, setLogoArray } from "./actions";

const Router = lazy(() => import("./Router"));

function App() {
	const dispatch = useDispatch();

	const currentTheme = useSelector((state) => state.theme.style);
	const [logos, setLogos] = useState([]);

	useEffect(() => {
		if ("scrollRestoration" in window.history) {
			window.history.scrollRestoration = "manual";
		}

		window.addEventListener("contextmenu", disableRightClick);

		return () => {
			window.removeEventListener("contextmenu", disableRightClick);
		};
	}, []);

	const client = contentful.createClient({
		space: "m8yddpej5gvi",
		environment: "master", // defaults to 'master' if not set
		accessToken: "8027rhYD8Xxg_Ilz59khRK-wGUIq6X1G7lCBqJBKVLE",
	});

	useEffect(() => {
		dispatch(setCmsClient(client));
	}, []);

	useEffect(() => {
		client
			.getEntry("4VWlMuXbjMj154SofCpBQ7")
			.then((entry) => {
				setLogos(entry.fields.logos);
				dispatch(setLoading(false));
			})
			.catch(() => {
				dispatch(setLoading(false));
				console.error;
			});
	}, []);

	useEffect(() => {
		cleanUpLogoArray();
	}, [logos]);

	/**
	 * @method cleanUpLogoArray
	 */
	const cleanUpLogoArray = () => {
		const cleanedUpArray =
			logos &&
			logos.map((image) => ({
				src: image.fields.logo.fields.file.url,
				title: image.fields.logo.fields.title,
			}));

		dispatch(setLogoArray(cleanedUpArray));
	};

	const theme = createTheme({
		palette: {
			primary: {
				main: "#fff",
			},
			secondary: {
				main: "#000",
			},
		},
		typography: {
			fontFamily: "Poppins, sans-serif",
		},
	});

	const disableRightClick = (e) => {
		e.preventDefault();
	};

	return (
		<>
			<ThemeProvider theme={theme}>
				<StyledThemeProvider theme={mainTheme[currentTheme]}>
					<Router />
					<GlobalStyles />
				</StyledThemeProvider>
			</ThemeProvider>
		</>
	);
}

export default App;
